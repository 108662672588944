<template>

   <v-app>
    <div class="container">
      <div class="center" v-if="!loading">
        <div class="d-flex my-10" v-if="!$auth.isAuthenticated">
          <v-btn class="mx-auto" color="error" @click="login()">Please Login</v-btn>
        </div>
        <div v-else>
          <div class="ticket" :style="ticketStyle()">
            <div class="center2 mt-4">
              <div v-if="ticket && boxOffice" style="text-align:center;">
                <h1 v-if="ticket.used" class="mb-5">Used Ticket</h1>
                <h1 v-else class="mb-5">Valid Ticket</h1>
                <h2>{{ticket.ticketNumber}}</h2>
                <h2>{{ticket.orderName}}</h2>
                <h2>{{ticket.eventName}}</h2>
                <h2>{{utils.dateToShortString(ticket.eventTimeStart)}}</h2>
                <h2>{{ticket.ticketTypeName}}</h2>
              </div>
              <div v-else-if="!boxOffice" style="text-align:center;">
                <h1>Invalid BoxOffice</h1>
              </div>
              <div v-else-if="!boxOffice.isUserOwner" style="text-align:center;">
                <h2>You do not have Permission for {{boxOffice.name}} Box Office</h2>
                <p class="my-5">Please ask you Admin to login and give you access</p>
                <v-btn class="mx-auto" color="black" @click="access()">Grant Access</v-btn>
              </div>
              <div v-else style="text-align:center;">
                <h1>Invalid Ticket</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2" style="text-align:center;" v-if="$auth.isAuthenticated">
          <p>Logged in as {{$auth.user.name}}<br/><v-btn class="mx-auto mt-1" small color="error" @click="logout()">Logout</v-btn></p>
        </div>
        <div class="d-flex">
          <img class="mx-auto mt-2" color="white" src="/poweredby.svg"><br/>
        </div>
      </div>
    </div>
  </v-app>
</template>
  
<script>
  import Api from '@/services/Api.js'; 
  import Utils from '@/services/Utils.js'
  import { getInstance } from "../../auth/index";  
    
  export default {
    components: {
    },  

    metaInfo() {
        return this.meta;
    },

    data () {
      return {
        meta:  {
          title: "Happy Tickets"
        },
        user: null,
        ticket: null,
        boxOffice: null,
        status: null,
        loading: true,
      }
    },
    
    async created() {
      this.loading = true
      this.$vuetify.theme.dark = true
      // Watch for the loading property to change before we check isAuthenticated
      this.watchLoad()
    },

    computed: {
      utils: function () {
        return Utils;
      },
    },
    
    methods: {

      async login() {
        await getInstance().loginWithPopup();
        this.watchLoad()
      },

      watchLoad() {
        var auth = getInstance();
        if (!auth.loading) {
          this.load()
        } else {
          auth.$watch("loading", loading => {
            if (loading === false) {
              this.load()
            }
          });
        }
      },

      logout() {
        Utils.logout(this, location.pathname)
      },

      access() {
        Utils.logout(this, "/Dashboard/Users/" + this.$route.params.boxOfficeId)
      },

      async load() {
        this.loading = true;
        if (getInstance().isAuthenticated) {
          this.user = await Api.post(this, "User", "currentUser", null, true);
          if (!this.user) {
            this.user.email = this.$auth.user.email;
            this.user.name = this.$auth.user.name;
            this.user = await Api.post(this, "User", "create", this.user);
          }
          this.boxOffice = await Api.post(this, "BoxOffice", "read", {id:this.$route.params.boxOfficeId});
          if (this.boxOffice && this.boxOffice.isUserOwner) {
            this.ticket = await Api.post(this, "Ticket", "read", {id:this.$route.params.ticketId}, true);
            if (!this.ticket) {
              this.status = "red"
            } else {
              if (this.ticket.used) {
                this.status = "yellow"
              } else {
                this.status = "green"
                Api.post(this, "Ticket", "update", {id:this.ticket.id, used:true});
              }
            }
          } else {
            this.status = "red"
          }
        }
        this.loading = false;
      },

      ticketStyle() {
        if (this.status == "green") return "background-color:green;"
        if (this.status == "yellow") return "background-color:yellow; color:black;"
        return "background-color:red;"
      }

    }

  }
</script>

<style>
.container {
  height: 100vh;
  position: relative;
}

.ticket {
  height: 400px;
  width: 300px;
  position: relative;
  border-radius: 25px;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.center2 {
  padding:7px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
